export default {
  prevSlideText: {
    id: 'cms_products_video_block_prev_slide',
    defaultMessage: 'Previous video',
  },
  nextSlideText: {
    id: 'cms_products_video_block_next_slide',
    defaultMessage: 'Next video',
  },
}
